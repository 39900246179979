@import 'variables';
// import Bootstrap mixins until all of EA is merged into main build
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.skin-megna-dark .topbar {
  background: #ffffff;
  border-bottom: black 1px solid;
}
.navbar-dark .navbar-nav .nav-link {
  color: #000;
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(74 74 74 / 75%);
}
.topbar .top-navbar .navbar-header {
  background: rgb(0 0 0 / 0%);
}
.navbar-header .navbar-brand {
  text-align: center;
  width: 100%;
}
.navbar-header .navbar-brand .logo {
  max-width: 150px;
}

.form-group {
  margin-bottom: 1rem;
}

// Right Side Bar
.right-sidebar {
  &.shw-rside {
    width: 320px;
  }
  
  .rpanel-title {
    padding: 1rem;
    font-size: 1rem;
    background: $blue;

    span {
      font-size: 1rem;
    }
  }
}

// Guest Layout
.guest {

  @include media-breakpoint-up(md) {
    .container-fluid {
      padding: 1rem 5rem;
    }
  }

  .footer {
    margin-left: 0;
  }
}
